.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');


.dashboardOrigamiContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}


html, body, #root{
  height: 100%;
  font-family: Kanit !important;
}

.headerDashboard{
  margin: 20px 0px;
  font-size: 22px;
  text-align: center;
}

.origamiIcon{
  width: 100px;
  height: 100px;
}

.fullHeight{
  min-height: 100vh;
}

.contentSection{
  padding: 20px;
  width: 100vw;
  font-family: Kanit !important;
}

.rigthContent{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.ant-typography{
  font-family: Kanit !important;
}

.hrGrey{
  border-top: 1px solid #cccccc;
}

.classIncomeToday{
  height: 380px;
}

.rowIncomeToday{
  padding: 5px 0px;
}

.formItemWithNoMargin{
  margin-bottom: 0px !important;
}

.optionChartWeekIncome{
  display: flex;
  justify-content: flex-end;
}

.buttonDownload{
  color: #fff;
  background-color: #e0000a;
  padding: 7px;
  border-radius: 5px;
}

.ModalContent{
  text-align: center;
}

.ModalContentLeft{
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.ant-input-outlined.ant-input-disabled{
  color:#000 !important;
}

.branchDetail{
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 16px;
}

.ant-layout-sider-trigger{
  background-color: #691b78 !important;
}

.ant-menu{
  font-family: 'Kanit' !important;
}

.tableSummaryTotal{
  font-family: 'Kanit';
  font-size: 17px;
  font-weight: 500;

  border: 1px solid #555555;
  border-radius: 10px;
  padding: 10px;
  width: 600px;

  background-color: #ebecff;
}

.summaryContent{
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}

.divCenter{
  text-align: center;
}

.divFlexSpaceBetween{
  display: flex;
  justify-content: space-between;
}


:where(.css-dev-only-do-not-override-1kuana8).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-dev-only-do-not-override-1kuana8).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
  background-color: #b5198a !important;
}


:where(.css-1kuana8).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover, :where(.css-1kuana8).ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
  background-color: #b5198a !important;
}

.centerSection{
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.divDetailSection{
  font-family: 'Kanit';
  font-size: 17px;
  font-weight: 500;
  background-color: #ffffff;
  width: 600px;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #cccccc;
  text-align: left;
}

.bankAccountTable{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .classIncomeToday{
    height: auto;
  }

  .origamiLogo{
    width: 80% !important;
  }

  .logo-vertical{
    text-align: center !important;
  }

  .ant-layout-sider-trigger{
    position: relative !important;
  }
}